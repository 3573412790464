@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: brown;
}

body {
  background-color: #0f0f0f;
  color: aliceblue;
  font-family: 'Nunito', sans-serif;
}

@layer utilities {

  .main-bg {
    background-color: #0f0f0f;
  },
  .p-tiny {
    padding: 1px;
  }
  .b-tiny {
    border-top: 1px;
  }
  .h-big {
    height: 80vh;
  }

  .h-large {
    height: 90vh;
  }

  .h-half {
    height: 50vh;
  }


  
}

.navbar {
  color: white;
  mix-blend-mode: difference;
}
.navbar-menu {
  color: #0f0f0f;
  mix-blend-mode: difference;
}

.menu {
  background-color: #0f0f0f;
}