.swiper {
    color: rgb(255, 255, 255);
    width: 100vw;
    height: 80vh;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    transition: all ease-in-out 0.5s;
  }

  .swiper-slide:hover img {
    opacity: 100%;
  }

  .swiper-slide section {
      padding: 4rem 2rem;
  }
  
  .swiper-slide {
    background-color: #0f0f0f;
    width: 90%;
    border-right: 1px solid rgb(48, 48, 48);
    border-bottom: 1px solid rgb(48, 48, 48);
  }

  @media (min-width: 768px) {

    .swiper-slide {
        width: 30%;
      }
    .swiper {
        height: 90vh;
      }
    .swiper-slide img {
        opacity: 0;
    }
      
  }
  
  /* .swiper-slide:nth-child(2n) {
    width: 60%;
  }
  
  .swiper-slide:nth-child(3n) {
    width: 40%;
  } */
